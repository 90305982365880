/* override Chakra styles for markdown text */
.markdown-content h1 {
  font-size: 2rem !important;
  font-weight: bold !important;
  margin-bottom: 1rem !important;
  color: #c50f3c !important;
}

.markdown-content h2 {
  font-size: 1.5rem !important;
  font-weight: bold !important;
  margin-bottom: 1rem !important;
  color: #c50f3c !important;
}

.markdown-content table {
  border-collapse: collapse !important;
  width: 100% !important;
  margin-bottom: 1rem !important;
}

.markdown-content th,
.markdown-content td {
  border: 1px solid #ddd !important;
  padding: 8px !important;
}

.markdown-content a {
  color: #9fcf66 !important;
}

.markdown-content a:hover {
  text-decoration: underline !important;
}

.markdown-content p {
  margin-bottom: 1rem !important;
}

.markdown-content ul {
  list-style-type: disc !important;
  padding-left: 1.5rem !important;
  list-style-position: inside !important;
}

.markdown-content ol {
  list-style-type: decimal !important;
  padding-left: 1.5rem !important;
  list-style-position: inside !important;
}
